import TokenLocker2 from 'contracts/abiTokenLocker2.json';

const list = [
  {
    key: 1,
    address: '0xBE383b4977ec9e7e97a9B97b5478FAd970c47a8D',
    title: 'Seed Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
  {
    key: 2,
    address: '0x3586c0FC900749Fb4Cea09455F3a1d84feDd2C23',
    title: 'Private Sale 1 Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
  {
    key: 3,
    address: '0xb2b0853Cb876c861b22a3da52904B2f07f38d796',
    title: 'Private Sale 2 Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
  {
    key: 4,
    address: '0xB16C21E0108FD9f6fF5FFd700EF1478315207Cbc',
    title: 'Strategic Partners Round',
    abi: TokenLocker2.abi,
    isCountdown: true,
  },
];

export default list;