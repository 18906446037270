import IconTwitter from 'resources/images/icon-twitter.png';
import IconTelegram from 'resources/images/icon-telegram.png';
import IconMedium from 'resources/images/icon-medium.png';
import IconInstagram from 'resources/images/icon-insta.png';
import IconYoutube from 'resources/images/icon-youtube.png';
import IconReddit from 'resources/images/icon-reddit.png';
import IconDiscord from 'resources/images/icon-discord.png';

const socialLinks = [
  {
    name: 'Insta',
    icon: IconInstagram,
    link: 'https://www.instagram.com/battlesagaofficial/'
  },
  {
    name: 'Reddit',
    icon: IconReddit,
    link: 'https://www.reddit.com/user/BattleSagaOfficial'
  },
  {
    name: 'Discord',
    icon: IconDiscord,
    link: 'https://discord.com/invite/2KNN6JXSWS'
  },
  {
    name: 'Telegram',
    icon: IconTelegram,
    link: 'https://t.me/BattleSaga_Official'
  },
  {
    name: 'Twitter',
    icon: IconTwitter,
    link: 'https://twitter.com/BattleSagaio'
  },

  {
    name: 'Youtube',
    icon: IconYoutube,
    link: 'https://www.youtube.com/channel/UCkByKhhFrDVhSnLzr_Zx-CA'
  },
  {
    name: 'Medium',
    icon: IconMedium,
    link: 'https://medium.com/@BattleSagaOfficial'
  }
];

export { socialLinks };
