import React, { useState, useEffect, FC } from 'react';
import { Button, Col, Row } from 'antd';
import classnames from 'classnames';
import Countdown, { zeroPad } from 'react-countdown';
import { getContract } from 'hook';
import { useWeb3React } from '@web3-react/core';
import { uniq } from 'lodash';
import BigNumber from 'bignumber.js';
import StatisticComponent from 'components/Statistic';
import { convertEToNumber, formatUnits, toNumber } from 'utils/helpers';
import {
  LINK_BATTLE_SAGA,
  TOKEN_ADDRESS,
  TYPE_OF_ANT_DESIGN
} from 'common/constant';
import showMessage from 'components/Message';
import TokenJSON from 'contracts/abiToken.json';

import Icon1 from 'resources/images/icon-1.svg';
import Icon2 from 'resources/images/icon-2.svg';
import Icon3 from 'resources/images/icon-3.svg';
import Icon4 from 'resources/images/icon-4.svg';
import Icon1White from 'resources/images/icon-1-white.png';
import Icon2White from 'resources/images/icon-2-white.png';
import Icon3White from 'resources/images/icon-3-white.png';
import Icon4White from 'resources/images/icon-4-white.png';
import Logo from 'resources/images/logo-big.png';

BigNumber.config({
  EXPONENTIAL_AT: 100
});

const convertHexToDecimal = (value: any) => {
  if (!value) {
    return '0';
  }

  const newValue = new BigNumber(value.toString())
    .dividedBy(Math.pow(10, 18))
    .toString();

  return newValue;
};

const sumTwoNumber = (a: number | string | null, b: number | string | null) => {
  return new BigNumber(a || 0).plus(b || 0).toString();
};

const minusTwoNumber = (
  a: number | string | null,
  b: number | string | null
) => {
  return new BigNumber(a || 0).minus(b || 0).toString();
};

const SummaryRoundComponent: FC<{
  account: null | undefined | string;
  data: any;
}> = ({ account, data }) => {
  const [totalValue, setTotalValue] = useState({}) as any;

  useEffect(() => {
    const newTotalValue = Object.values(data).reduce(
      (acc: any, cur: any) => ({
        allocationAmount: sumTwoNumber(
          acc.allocationAmount,
          cur.allocationAmount
        ),
        claimedAmount: sumTwoNumber(acc.claimedAmount, cur.claimedAmount),
        availableAmount: sumTwoNumber(acc.availableAmount, cur.availableAmount),
        claimableAmount: sumTwoNumber(acc.claimableAmount, cur.claimableAmount)
      }),
      {
        allocationAmount: 0,
        claimedAmount: 0,
        availableAmount: 0,
        claimableAmount: 0
      }
    );
    setTotalValue(newTotalValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, data]);

  return (
    <Row
      gutter={[
        0,
        {
          xs: 48,
          sm: 48,
          lg: 58
        }
      ]}
    >
      <Col span={24}>
        <div className='container'>
          <a href={LINK_BATTLE_SAGA} target='_blank' rel='noreferrer'>
            <img src={Logo} alt='Battle Saga' />
          </a>
          <h1 className='title'>Vesting Portal</h1>
        </div>
      </Col>
      <Col span={24}>
        <Row gutter={[30, 16]}>
          <Col xs={24} sm={12} xl={6}>
            <StatisticComponent
              percent={totalValue.allocationAmount}
              isConnected={!!account}
              title='Allocation Amount'
              iconUrl={Icon1}
              className='statistic'
            />
          </Col>
          <Col xs={24} sm={12} xl={6}>
            <StatisticComponent
              percent={totalValue.availableAmount}
              isConnected={!!account}
              title='Available Amount'
              iconUrl={Icon2}
              className='statistic'
            />
          </Col>
          <Col xs={24} sm={12} xl={6}>
            <StatisticComponent
              percent={totalValue.claimedAmount}
              isConnected={!!account}
              title='Claimed Amount'
              iconUrl={Icon3}
              className='statistic'
            />
          </Col>

          <Col xs={24} sm={12} xl={6}>
            <StatisticComponent
              percent={totalValue.claimableAmount}
              isConnected={!!account}
              title='Claimable Amount'
              iconUrl={Icon4}
              className='statistic'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const RoundComponent: FC<{
  updateAllData: any;
  title?: string;
  isCountdown?: null | undefined | number;
  address: string;
  abi?: any;
  communityRound?: boolean;
  index: number;
  setListRoundVisible?: any;
  keyItem?: number;
}> = ({
  updateAllData,
  title = '',
  isCountdown = false,
  address,
  abi,
  communityRound,
  index,
  setListRoundVisible,
  keyItem
}) => {
  const { account, library } = useWeb3React();

  const [roundData, setRoundData] = useState({
    allocationAmount: null,
    claimedAmount: null,
    claimableAmount: null,
    availableAmount: null
  });
  const [isShowBtnClaim, setIsShowBtnClaim] = useState(false);

  const [timeCountdown, setTimeCountdown] = useState(0);
  const [isBtnClaim, setBtnClaim] = useState(false);

  const rendererCountdown = ({ days, hours, minutes, seconds }: any) => {
    return (
      <ul className='countdown'>
        <li className='time'>
          <span className={classnames('value', { highlight: days })}>
            {days}D
          </span>
        </li>
        :
        <li className='time'>
          <span className={classnames('value', { highlight: days || hours })}>
            {hours}H
          </span>
        </li>
        :
        <li className='time'>
          <span
            className={classnames('value', {
              highlight: days || hours || minutes
            })}
          >
            {minutes}M
          </span>
        </li>
        :
        <li className='time'>
          <span
            className={classnames('value', {
              highlight: days || hours || minutes || seconds
            })}
          >
            {seconds}S
          </span>
        </li>
      </ul>
    );
  };

  /**
   * get Balance
   * @returns balance
   */
  const getBalance = async (
    library: any,
    address: string,
    tokenAddress: string
  ) => {
    const tokenInst = getContract(tokenAddress, TokenJSON.output.abi, library);
    if (address) {
      const balance = await tokenInst.balanceOf(address);
      const decimals = await tokenInst.decimals();

      return {
        balance: convertEToNumber(formatUnits(balance, 'wei'), decimals)
      };
    } else {
      return {
        balance: 0
      };
    }
  };

  const claimRound = async () => {
    setIsShowBtnClaim(true);

    const { balance } = await getBalance(library, address, TOKEN_ADDRESS);

    if (balance < (roundData?.claimableAmount || 0)) {
      showMessage(
        TYPE_OF_ANT_DESIGN.ERROR,
        'The claiming contract runs out of SHOE, please contact the Graviton administrators.'
      );

      setIsShowBtnClaim(false);
      return;
    }

    try {
      const contract = getContract(address, abi, library, account as string);
      const res = await contract.unlock(account);
      const receipt = await library.waitForTransaction(res.hash);
      if (receipt.status) {
        getAllData();
      }
      setIsShowBtnClaim(false);
    } catch (err) {
      setIsShowBtnClaim(false);
    }
  };

  const handleComplete = () => {
    setTimeCountdown(0);
    setBtnClaim(true);
  };

  const getAllData = async () => {
    try {
      const contract = getContract(address, abi, library, account as string);
      const lockedAmount = await contract.lockedAmountOf(account);
      const data = await Promise.all([
        contract.lockedAmountOf(account),
        contract.releasedAmountOf(account)
      ]);
      let claimableAmount = 0;
      try {
        claimableAmount = (await lockedAmount.gt(0))
          ? await contract.canUnlockAmountOf(account)
          : 0;
      } catch (ex) {
        claimableAmount = 0;
      }
      const [allocationAmount, claimedAmount] = data;
      const availableAmount = minusTwoNumber(
        convertHexToDecimal(allocationAmount),
        convertHexToDecimal(claimedAmount)
      );

      const newData = {
        allocationAmount: convertHexToDecimal(allocationAmount),
        claimedAmount: convertHexToDecimal(claimedAmount),
        claimableAmount: convertHexToDecimal(claimableAmount),
        availableAmount: availableAmount
      };

      if (!toNumber(newData.allocationAmount)) {
        setListRoundVisible((prevState: any) =>
          prevState.filter((item: any) => item !== keyItem)
        );
      } else {
        setListRoundVisible((prevState: any) => uniq([...prevState, keyItem]));
      }

      setRoundData((prevState: any) => ({
        ...prevState,
        ...newData
      }));

      updateAllData({ ...newData, index });
    } catch (err) {
      console.log('err=', err);
    }
  };

  useEffect(() => {
    if (account) {
      getAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, abi, address, index, library]);

  useEffect(() => {
    if (account && isCountdown) {
      const contract = getContract(address, abi, library, account as string);
      const getCountdownData = async () => {
        try {
          let time;
          const currentTime = Math.floor(new Date().getTime() / 1000);
          if (communityRound) {
            time = await contract._timestamps(0);
            setTimeCountdown(time.toNumber() * 1000);
          } else {
            time = await contract._unlockTime();
            setTimeCountdown(time.toNumber() * 1000);
          }
          if (currentTime >= time) {
            setBtnClaim(true);
          } else {
            setBtnClaim(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getCountdownData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isCountdown, address, abi, library]);

  return (
    <div className={`round  round--${index % 3}`}>
      <div className={`content`}>
        <h4 className='round__title'>
          <div className='text'>{title}</div>
        </h4>

        <Row gutter={[{ xs: 45, md: 35, xxl: 70 }, 24]}>
          <Col xs={24} sm={12}>
            <StatisticComponent
              isConnected={!!account}
              title='Allocation Amount'
              iconUrl={Icon1White}
              percent={roundData.allocationAmount}
              className='statistic-card'
            />
          </Col>
          <Col xs={24} sm={12}>
            <StatisticComponent
              isConnected={!!account}
              title='Available Amount'
              iconUrl={Icon2White}
              percent={roundData.availableAmount}
              className='statistic-card'
            />
          </Col>
          <Col xs={24} sm={12}>
            <StatisticComponent
              isConnected={!!account}
              title='Claimed Amount'
              iconUrl={Icon3White}
              percent={roundData.claimedAmount}
              className='statistic-card'
            />
          </Col>
          <Col xs={24} sm={12}>
            <StatisticComponent
              isConnected={!!account}
              title='Claimable Amount'
              iconUrl={Icon4White}
              percent={roundData.claimableAmount}
              className='statistic-card'
            />
          </Col>
        </Row>

        <div className='round__bottom'>
          {isBtnClaim && (
            <Button
              loading={isShowBtnClaim}
              type='primary'
              onClick={claimRound}
              className='button-claim'
              disabled={!toNumber(roundData.claimableAmount) || !account}
            >
              Claim
            </Button>
          )}
          {!!timeCountdown && !isBtnClaim && (
            <div className='countdown-wrapper'>
              <Countdown
                date={timeCountdown}
                renderer={rendererCountdown}
                onComplete={handleComplete}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SummaryRoundComponent, RoundComponent };
